/* You can add global styles to this file, and also import other style files */

body {
  font-family: 'Playfair Display', serif;
  margin: 0;
  background-color: #e6e6e6;
  padding: 0;
}

html{
  scroll-behavior: smooth;
}
/* html, body { height: 100%; }
body { margin: 0; font-family: Roboto, "Helvetica Neue", sans-serif; } */
